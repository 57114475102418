<template>
  <div id="rebateTransfer">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('transfer.REBATE_TRANSFER_REQUEST') }}</h2>

          <p></p>
        </div>

        <div class="form_container">
          <el-form :model="transferForm" :rules="rules" ref="transferForm">
            <div class="form_list_inner">
              <ul class="clearfix">
                <li>
                  <SelectForm
                    v-model="transferForm.rebateAccount"
                    :label="$t('common.keys.FROM') + ' ' + $t('common.keys.rebateAccount')"
                    name="rebateAccount"
                    class="showPlaceholder"
                  >
                    <el-option
                      v-for="item in rebates"
                      :key="item.login"
                      :label="`${item.login} - ${item.balance}  ${item.currency}`"
                      :value="item.login"
                      :data-testid="item.login"
                      :disabled="item.balance < 0"
                    ></el-option>
                  </SelectForm>
                </li>

                <li>
                  <SelectForm
                    v-model="transferForm.tradingAccount"
                    :label="$t('common.keys.TO') + ' ' + $t('transfer.account')"
                    name="tradingAccount"
                    class="showPlaceholder"
                  >
                    <el-option
                      v-for="item in tradingAccountList"
                      :key="item.login"
                      :label="item.login | accountListFormatter(item, transferTranslationObj)"
                      :value="item.login"
                      :data-testid="item.login"
                    ></el-option>
                  </SelectForm>
                </li>

                <li>
                  <numeric-input
                    v-model="transferForm.amount"
                    :precision="2"
                    :showCurrency="true"
                    :showCurrencySymbol="selectedRebateAccountCurrency | currencySymbol"
                    :currency="selectedRebateAccountCurrency"
                    :label="$t('common.keys.amount')"
                    name="amount"
                    ref="numberInput"
                  ></numeric-input>
                </li>
              </ul>
            </div>

            <div class="form_button">
              <el-button class="purple_button" @click="submitForm('transferForm')" data-testid="submit">
                {{ $t('common.keys.SUBMIT') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiToApplyTransferView, apiApplyTransfer } from '@/resource';
import NumericInput from '@/components/NumericInput';
import mixin from '@/mixins';
import blacklist from '@/mixins/blacklist';

export default {
  components: { NumericInput },
  mixins: [mixin, blacklist],
  data() {
    return {
      //对话框
      accountList: [],
      tradingAccountList: [],
      rebates: [],
      transferForm: {
        rebateAccount: '',
        amount: '',
        tradingAccount: ''
      },
      transferTranslationObj: {
        1: this.$t('transfer.tradingAccount'),
        3: this.$t('transfer.rebateAccount')
      },
      selectedRebateAccountCurrency: '',
      availableBalance: '0',
      rules: {
        rebateAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        amount: this.getAmountRules(),
        tradingAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ]
      },
      userId: this.$store.state.common.CUID,
      prevRoute: null
    };
  },

  watch: {
    'transferForm.rebateAccount'(account) {
      this.rebateAccountChange(account);
    },
    availableBalance() {
      this.rules.amount = this.getAmountRules();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  filters: {
    accountListFormatter: function (value, item, transferTranslationObj) {
      if (value === undefined || value === null) {
        return '';
      } else {
        const currency = item.currency || ' ';
        const accountType =
          item.accountDealType === 1 || item.accountDealType === 3 ? transferTranslationObj[item.accountDealType] : '';
        value = item.login + ' - ' + currency + (accountType ? ' (' + accountType + ')' : '');
      }
      return value;
    }
  },
  methods: {
    //form表单提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) return false;
        else if (!this.checkRebateTransferBlacklist()) {
          this.displayErrMsg('transfer');
        } else this.applyTransfer();
      });
    },
    getAccountInfo() {
      apiToApplyTransferView({
        qUserId: this.userId
      }).then(resp => {
        if (resp.data.code === 0) {
          const respData = resp.data.data;
          this.accountList = respData.accountList;
          this.rebates = respData.rebates;
          this.rebates.forEach(element => {
            element.balance = element.balance.toFixed(2);
          });
          // 默认选中首页选中的账号
          if (this.rebates && this.rebates.length > 0) {
            const firstAccountIndex = this.rebates.findIndex(account => Number(account.balance) >= 0);
            if (firstAccountIndex !== -1) {
              this.transferForm.rebateAccount = this.rebates[firstAccountIndex].login;
              this.selectedRebateAccountCurrency = this.rebates[firstAccountIndex].currency;
            }
          }
          this.rebateAccountChange(this.transferForm.rebateAccount);
        }
      });
    },
    // 佣金账号改变
    rebateAccountChange(val) {
      const [availableBalance] = this.rebates.filter(item => {
        return item.login === val;
      });
      if (availableBalance == undefined) {
        return;
      }
      this.availableBalance = availableBalance.balance;
      this.selectedRebateAccountCurrency = availableBalance.currency;
      let selectionList = this.rebates.concat(this.accountList).filter(item => {
        return item.login !== val;
      });
      this.tradingAccountList = selectionList.filter(a => {
        return a.currency == availableBalance.currency;
      });
      this.transferForm.tradingAccount = this.tradingAccountList.length > 0 ? this.tradingAccountList[0].login : null;
    },
    applyTransfer() {
      apiApplyTransfer(
        {
          qUserId: this.userId,
          fromAccount: this.transferForm.rebateAccount,
          toAccount: this.transferForm.tradingAccount,
          amount: this.transferForm.amount,
          transferType:
            this.tradingAccountList.find(item => item.login === this.transferForm.tradingAccount).accountDealType === 1
              ? 3
              : 4
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.$message.success({ duration: 5000, message: this.$t('transfer.yourRebateTransferHasBeenProcessed') });
          this.bus.$emit('updateTransferHistory', true);
          this.$nextTick(function () {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        } else if (resp.data.code === 551) {
          this.transferForm = {
            rebateAccount: '',
            amount: '',
            tradingAmount: ''
          };
          this.$refs.transferForm.resetFields();
          this.getAccountInfo();
        }
      });
    },
    async checkRebateTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true;
      else
        await this.getRebateTransferBlacklist().then(resp => {
          return resp;
        });
    },
    getAmountRules() {
      return [
        {
          required: true,
          message: this.$t('transfer.PleaseEnterTransferInformation'),
          trigger: 'blur'
        },
        {
          validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            if (value == 0) {
              callback(new Error(this.$t('withdraw.Itcannotbe0')));
            } else if (value > this.availableBalance) {
              callback(new Error(this.$t('transfer.transferAmountIsGreaterThanAvailableBalance')));
            }
            callback();
          },
          trigger: 'blur'
        }
      ];
    }
  },
  mounted() {
    this.getAccountInfo();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/rebateTransfer.scss';
</style>
