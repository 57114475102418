import { apiRebateTransferBlacklist, apiRebateWithdrawBlacklist } from '@/resource'
import { defaultVariableMapping } from '@/components/profile/SubProfile.js'

export default {
  computed: {
    hasTransferPermission() {
      return this.$store.state.common.operateAmountPermisison
    },
  },
  methods: {
    goToRebateTransfer() {
      this.getRebateTransferBlacklist().then(resp => {
        resp
          ? this.$router.push({ name: '/rebateTransfer', params: { passBlacklist: true } })
          : this.displayErrMsg('transfer')
      })
    },
    goToRebateWithdraw() {
      this.getRebateWithdrawBlacklist().then(resp => {
        resp ? this.$router.push({ path: '/rebateWithdraw' }) : this.displayErrMsg('withdraw')
      })
    },
    async getRebateTransferBlacklist() {
      return new Promise(resolve => {
        apiRebateTransferBlacklist().then(resp => {
          resolve(resp.data.data)
        })
      })
    },
    async getRebateWithdrawBlacklist() {
      return new Promise(resolve => {
        apiRebateWithdrawBlacklist().then(resp => {
          resolve(resp.data.data)
        })
      })
    },
    displayErrMsg(transaction) {
      switch (transaction) {
        case 'transfer':
          return this.$message({
            message: this.$t('withdraw.blackListTransfer', { supportEmail: this.GLOBAL_CONTACT_EMAIL }),
            type: 'error',
          })
        default:
          return this.$message({
            message: this.$t('withdraw.blackList', { supportEmail: this.GLOBAL_CONTACT_EMAIL }),
            type: 'error',
          })
      }
    },
  },
}
